import { useProjectProgress } from '../../../../../../../../app/hooks/useProjectProgress';

type Props = {
  projectUuid: string
};

export const ProgressInfo: React.FC<Props> = ({ projectUuid }) => {
  const progress = useProjectProgress(projectUuid);

  return (
    <span>
      <svg><use xlinkHref="#icon-checkbox"/></svg>
      {progress || 0}%
    </span>
  );
}