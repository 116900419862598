import { format } from 'date-fns';
import cn from 'classnames';
import ru from 'date-fns/esm/locale/ru/index.js';

import './EventInfo.scss';

type Props = {
  date: string
  clientUuid?: string,
  className?: any
};

export const EventInfo: React.FC<Props> = ({ date, clientUuid, className }) => {
  const formatedDate = format(new Date(date), 'dd MMM HH:mm', { locale: ru });
  const actor = clientUuid ? 'Вы' : 'ASH';

  return (
    <div className={cn('EventInfo', className)}>
      {actor} <span className="EventInfo__separator"/> {formatedDate}
    </div>
  );
};