import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchBriefing } from '../../../briefing/store/briefingSlice';
import { Preloader } from '../../../common/preloader/Preloader';
import { fetchEventsByProject } from '../../store/timelineEventSlice';
import { Events } from './events/Events';
import { FeedCompose } from './feedCompose/FeedCompose';
import { showMenu, hideMenu } from '../../../menu/menuSlice';

import './Timeline.scss';

export const useContentGuard = () => {
  const dispatch = useDispatch();
  const [ isLoaded, setIsLoaded ] = useState(false);
  const { projectUuid } = useParams<{projectUuid: string}>();

  useEffect(() => {
    Promise.all([
      dispatch(fetchEventsByProject(projectUuid)),
      dispatch(fetchBriefing(projectUuid))
    ]).then(() => {
      setIsLoaded(true);
    });
  }, [ projectUuid, dispatch ]);

  return isLoaded;
}

export const Timeline = () => {
  const dispatch = useDispatch();
  const isContentLoaded = useContentGuard();

  useEffect(() => {
    dispatch(hideMenu());

    return () => {
      dispatch(showMenu());
    }
  }, [ dispatch ]);

  if (!isContentLoaded) {
    return <Preloader primary centering/>;
  }

  return (
    <div className="Timeline">
      <Events/>
      <FeedCompose/>
    </div>
  );
}