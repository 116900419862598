import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { AuthModel } from '../../app/model/AuthModel';

type Props = {
  children: any
}

export const PrivateRoutes: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  return AuthModel.isAuthenticated()
    ? children
    : <Redirect to={{
        pathname: process.env.REACT_APP_DEV ? '/password-login' : '/login',
        search: location.search,
        state: { referrer: location }
    }}/>;
}