import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from '../features/auth/authSlice';
import { answerReducer } from '../features/briefing/store/answerSlice';
import { briefingReducer } from '../features/briefing/store/briefingSlice';
import { contentReducer } from '../features/briefing/store/contentSlice';
import { navigationReducer } from '../features/briefing/store/navigationSlice';
import { partReducer } from '../features/briefing/store/partSlice';
import { progressReducer } from '../features/briefing/store/progressSlice';
import { questionReducer } from '../features/briefing/store/questionSlice';
import { stepReducer } from '../features/briefing/store/stepSlice';
import { menuReducer } from '../features/menu/menuSlice';
import { projectsReducer } from '../features/project/projectsSlice';
import { timelineEventReducer } from '../features/project/store/timelineEventSlice';

export const store = configureStore({
  reducer: {
    menu: menuReducer,
    projects: projectsReducer,
    auth: authReducer,
    briefing: combineReducers({
      briefing: briefingReducer,
      progress: progressReducer,
      navigation: navigationReducer,
      content: contentReducer,
      part: partReducer,
      step: stepReducer,
      question: questionReducer,
      answer: answerReducer,
    }),
    project: combineReducers({
      event: timelineEventReducer
    })
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;