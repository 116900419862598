import { Link } from 'react-router-dom';

import './ProjectHeadline.scss';

type Props = {
  title: string
};

export const ProjectHeadline: React.FC<Props> = ({ title }) => (
  <div className="ProjectHeadline">
    <div className="ProjectHeadline__content Wrap">
      <Link to="../" className="ProjectHeadline__btn-back">
        <svg><use xlinkHref="#icon-arrow"/></svg>
      </Link>
      <span className="ProjectHeadline__title">{title}</span>
    </div>
  </div>
);