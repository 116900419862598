import { useSelector } from "react-redux";
import { TimelineEvent, TimelineEventTypes } from "../../../../../../app/model/ProjectTimelineModel";
import { RootState } from "../../../../../../app/store";
import { selectTimelineEventById } from "../../../../store/timelineEventSlice";
import { ContractType } from './contractType/ContractType';
import { StageType } from "./stageType/StageType";
import { ContractActType } from "./ContractActType";
import { MessageType } from "./messageType/MessageType";
import cn from 'classnames';

import './Event.scss';

type Props = {
  eventId: string
};

export const Event: React.FC<Props> = ({ eventId }) => {
  const event = useSelector((state: RootState) => selectTimelineEventById(state, eventId)) as TimelineEvent;
  const isClient = !!event.clientUuid;
  let component: any;

  switch (event.type) {
    case TimelineEventTypes.CONTRACT:
      component = <ContractType event={event}/>;
        break;
      case TimelineEventTypes.STAGE:
        component = <StageType event={event}/>;
        break;
      case TimelineEventTypes.CONTRACT_ACT:
        component = <ContractActType event={event}/>;
        break;
      case TimelineEventTypes.MESSAGE:
        component = <MessageType event={event}/>;
        break;
    default:
      component = <div>Default</div>;
  }

  return (
    <div className={cn('Event', { 'Event_client': isClient, 'Event_user': !isClient })}>
      <div className="Event__content">
        {component}
      </div>
    </div>
  );
};