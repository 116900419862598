import { axiosInstance } from '.';
import { Tokens, TokensManager } from './TokensManager';

export type Client = {
  phone: string
  email: string
  firstName: string
  middleName: string
  lastName: string
};

export type AuthPasswordData = {
  phone: string
  password: string
  userAgent?: string
};

export type AuthenticationKeyData = {
  key: string
  clientUuid: string
  userAgent?: string
};

export class AuthModel {

  static async loginPassword(data: AuthPasswordData): Promise<void> {
    return axiosInstance.post('passport/auth/password', {
      phone: data.phone,
      password: data.password,
      userAgent: navigator.userAgent
    }).then(res => TokensManager.setTokens(res.data));
  }

  static async loginPhone(data: { phone: string }): Promise<void> {
    return axiosInstance.post('passport/auth/phone', data);
  }

  static async confirmEntry(data: { phone: string, code: string }): Promise<void> {
    return axiosInstance.post('passport/auth/confirm-entry', {
      phone: data.phone,
      code: data.code,
      userAgent: navigator.userAgent
    }).then(res => TokensManager.setTokens(res.data));
  }

  static async loginAuthenticationKey(data: AuthenticationKeyData): Promise<void> {
    return axiosInstance.post('passport/auth/authentication-key', {
      key: data.key,
      clientUuid: data.clientUuid,
      userAgent: navigator.userAgent
    }).then(res => TokensManager.setTokens(res.data));
  }

  static async refreshToken(): Promise<Tokens> {
    return axiosInstance.post('passport/auth/refresh-token', {
      refreshToken: TokensManager.getRefreshToken()
    }).then(res => {
      TokensManager.setAccessToken(res.data.accessToken);
      TokensManager.setRefreshToken(res.data.refreshToken);
      return res.data;
    });
  }

  static isAuthenticated(): boolean {
    return !!TokensManager.getAccessToken();
  }

}