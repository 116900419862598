import { Link } from 'react-router-dom';
import { Hamburger } from './hamburger/Hamburger';
import { MenuContent } from './menuContent/MenuContent';
import { useSelector } from 'react-redux';
import { selectProfile } from '../auth/authSlice';
import { RootState } from '../../app/store';
import { selectMenuShow } from './menuSlice';

import './Menu.scss';

export const Menu = () => {
  const profile = useSelector((state: RootState) => selectProfile(state));
  const isShow = useSelector((state: RootState) => selectMenuShow(state))

  if (!isShow) {
    return null;
  }

  return (
    <>
      <header className="Menu">
        <div className="Menu__content Wrap">
          <Link to="/">
            <img className="Menu__logo" src="/images/logo.svg" alt="Логотип компании"/>
          </Link>
          {profile && <Hamburger/>}
        </div>
      </header>
      <MenuContent/>
    </>
  );
};