import React from "react";
import { Button } from "../../../../../../../common/button/Button";
import { Preloader } from "../../../../../../../common/preloader/Preloader";
import { PrivateFile } from "../../../../../../../common/privateFile/PrivateFile";

type Props = {
  fileName: string
};

export const DoneContent: React.FC<Props> = ({ fileName }) => (
  <PrivateFile name={fileName} render={({ handleDownload }) => (
    <div className="Paragraph">
      Этап завершен, результат вы можете скачать по этой&nbsp;
      {
        fileName
          ? <Button className="Link" onClick={handleDownload}>ссылке.</Button>
          : <Preloader inline/>
      }
    </div>
  )}/>
);

