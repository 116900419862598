import { axiosInstance } from ".";

export type Project = {
  uuid: string
  name: string
  progress: number
  address: string
  coords: string
  createdAt: string
  deadline: string
  lastContract: string | null
};

export type Contract = {
  finishDate: string
  name: string
  number: string
  signDate: string
  startDate: string
};

export class ProjectModel {

  static async getProjects(): Promise<Project[]> {
    return await axiosInstance.get('/management/pub/projects').then(res => res.data);
  }

  static async getProject(projectUuid: string): Promise<Project> {
    return await axiosInstance.get(`/management/pub/projects/${projectUuid}`).then(res => res.data);
  }

  static async getLastContract(projectUuid: string): Promise<Contract> {
    return await axiosInstance.get(`/management/pub/projects/${projectUuid}/last-contract`).then(res => res.data);
  }

}