import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { BriefingModel, Question } from "../../../app/model/BriefingModel";
import { RootState } from "../../../app/store";

export const fetchQuestions = createAsyncThunk('briefing/question',
  async (stepId: string) => await BriefingModel.fetchQuestions(stepId)
);

const quesitonEntityAdapter = createEntityAdapter<Question>();

const questionSlice = createSlice({
  name: 'question',
  initialState: quesitonEntityAdapter.getInitialState(),
  reducers: { },
  extraReducers: builder => {
    builder.addCase(fetchQuestions.fulfilled, (state, action) => {
      quesitonEntityAdapter.upsertMany(state, action.payload);
    });
  }
});

export const selectQuestionsByStep = createSelector(
  (state: RootState) => state.briefing.question,
  (_: any, stepId: string) => stepId,
  (entityState, stepId) => entityState.ids
    .filter(id =>
      (entityState.entities[id] as Question).stepId === stepId
    )
    .map(id => entityState.entities[id])
);

export const questionReducer = questionSlice.reducer;