import { axiosInstance } from ".";

export type File = {
  name: string
  originalName: string
};

export const OBJECT_STORAGE_PATH = process.env.REACT_APP_OBJECT_STORAGE_PATH;

export class ObjectStorageModel {

  static upload(formData: FormData): Promise<File[]> {
    return axiosInstance.post('/object-storage/files?privated=true', formData).then(res => res.data);
  }

  static delete(name: string): Promise<void> {
    return axiosInstance.delete(`/object-storage/files/${name}`);
  }

  static async headFile(name: string): Promise<File> {
    return axiosInstance.get(`/object-storage/files/${name}/head`).then(res => res.data);
  }

  static async getFileLink(name: string): Promise<string> {
    const token = await axiosInstance.get(`/object-storage/files/${name}/download-token`).then(res => res.data.token);
    return `/api/object-storage/files/${name}?token=${token}`;
  }

}