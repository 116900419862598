import  './UserEventLayout.scss';

type Props = {
  icon?: string
  number?: string
  children: any
};

export const UserEventLayout: React.FC<Props> = ({ icon, number, children }) => (
  <div className="UserEventLayout">
    <div className="UserEventLayout__aside">
      {!!icon && <svg><use xlinkHref={`#icon-${icon}`}/></svg>}
      {!icon && <span>#{number}</span>}
    </div>
    <div className="UserEventLayout__content">
      {children}
    </div>
  </div>
);