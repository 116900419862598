import { axiosInstance } from '.';

export type Profile = {
  phone: string
  email: string
  firstName: string
  middleName: string
  lastName: string
};

export class ProfileModel {

  static fetchProfile(): Promise<Profile> {
    return axiosInstance.get('passport/pub/profile').then(res => res.data);
  }

  static updateProfile(profile: Profile): Promise<Profile> {
    return axiosInstance.put('passport/pub/profile', profile).then(() => profile);
  }

}