import debounce from "just-debounce-it";
import { useEffect } from "react";

export const SetFixedVh = () => {
  const setFixedVh = () => {
    document.documentElement.style.setProperty('--fixedVh', `${document.documentElement.clientHeight}px`)
  };

  useEffect(() => {
    const handleResize = debounce(setFixedVh, 300);

    window.addEventListener('resize', handleResize);

    setFixedVh();

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return null;
}