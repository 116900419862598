import React, { useEffect, useRef } from 'react';
import * as Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';

type Props = {
  location: string,
  className?: any
};

export const Map: React.FC<Props> = ({ location, className }) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const coords = location.split(',') as any;

  useEffect(() => {
    const container = mapContainerRef.current as HTMLDivElement;
    const shift = 0.1 * (container.clientWidth / 1200);

    const map = Leaflet.map(
      container,
      {
        center: coords.map((c: string, i: number) => !i ? c : +c + shift),
        zoom: 12,
        zoomControl: false
      }
    );

    map.dragging.disable();
    map.touchZoom.disable();
    map.doubleClickZoom.disable();
    map.scrollWheelZoom.disable();
    map.boxZoom.disable();
    map.keyboard.disable();
    map.attributionControl.setPrefix('');

    Leaflet.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        id: 'daikon573/ckj5qzkcqdro719rpney72uvr',
        accessToken: 'pk.eyJ1IjoiZGFpa29uNTczIiwiYSI6ImNreXdzZm42YzBiZW0yb214Yjh1YnpndW8ifQ.4Nh4X5C9A6JdqLBlnP0vaw'
      }
    ).addTo(map);

    Leaflet.marker(coords, {
      icon: Leaflet.icon({
        iconUrl: '/images/map-marker.svg',
        iconSize: Leaflet.point(35, 35)
      })
    }).addTo(map);

    return () => {
      map.remove();
    };
  }, [ coords ]);

  return (
    <div className={className} ref={mapContainerRef}/>
  );
}