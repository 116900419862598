import { TimelineEvent, TimelineStage } from "../../../../../../../app/model/ProjectTimelineModel";
import React from "react";
import { UserEventLayout } from "../userEventLayout/UserEventLayout";
import { EventInfo } from "../eventInfo/EventInfo";

import './StageType.scss';
import { BriefingType } from "./briefingType/BriefingType";
import { EventHeadline } from "../eventHeadline/EventHeadline";
import { useSelector } from "react-redux";
import { selectLastStageEvent } from "../../../../../store/timelineEventSlice";
import { RootState } from "../../../../../../../app/store";

type StageProps = {
  event: TimelineEvent
  active: boolean
};

const Stage: React.FC<StageProps> = ({ event, active }) => {
  const stage = event.data as TimelineStage;

  return (
    <UserEventLayout number={stage.number}>
      <EventInfo date={event.createdAt} className="mb-1"/>
      <EventHeadline deadline={stage.deadline} className="mb-4" done={!active}/>
      <span className="Event__title mb-3">
        Запущен этап {stage.number}. «{stage.name}»
      </span>
      <p className="Paragraph" dangerouslySetInnerHTML={{ __html: stage.description }}/>
    </UserEventLayout>
  );
}

type Props = {
  event: TimelineEvent,
};

export const StageType: React.FC<Props> = ({ event }) => {
  const stage = event.data as TimelineStage;
  const lastStageEvent = useSelector((stage: RootState) => selectLastStageEvent(stage));
  const isActive = lastStageEvent.id === event.id;

  switch (stage.type) {
    case 'common':
      return <Stage event={event} active={isActive}/>
    case 'briefing':
      return <BriefingType event={event} active={isActive}/>
    default:
      return <div>Default</div>;
  }
};
