import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Client } from "../../app/model/AuthModel";
import { Profile, ProfileModel } from "../../app/model/ProfileModel";
import { RootState } from "../../app/store";

export const fetchProfile = createAsyncThunk('auth/fetchProfile',
  async () => await ProfileModel.fetchProfile()
);

export const updateProfile = createAsyncThunk('auth/updateProfile',
  async (profile: Profile, { rejectWithValue }) =>
    await ProfileModel.updateProfile(profile).catch(err => rejectWithValue(err.response.data))
);

type InitialState = {
  profile: null | Client,
  isLoadingProfile: boolean
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    profile: null,
    isLoadingProfile: false
  } as InitialState,
  reducers: {
    setProfile(state, action) {
      state.profile = action.payload;
    },
    dropProfile(state) {
      state.profile = null;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchProfile.pending, (state) => {
      state.isLoadingProfile = true;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.isLoadingProfile = false;
    });
    builder.addCase(fetchProfile.rejected, (state) => {
      state.isLoadingProfile = false;
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  }
});

export const authReducer = authSlice.reducer;

export const { setProfile, dropProfile } = authSlice.actions;

export const selectProfile = (state: RootState) => state.auth.profile;
export const selectIsLoadingProfile = (state: RootState) => state.auth.isLoadingProfile;
