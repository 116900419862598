import { BriefingContent } from "../../../app/model/BriefingModel";
import { ActiveEntity, BriefingNav } from "./navigationSlice";

export const briefingNavigationPrapare = (payload: {content: BriefingContent, activeEntity: ActiveEntity}) => {
  const { content, activeEntity } = payload;
  const { partId, stepId } = activeEntity;
  const { projectUuid, parts } = content;
  const nav: BriefingNav = {prevPath: null, nextPath: null};
  const getStepPath = (stepId: string) => `/briefings/${projectUuid}/steps/${stepId}`;
  const getPartPath = (partId: string) => `/briefings/${projectUuid}/parts/${partId}`;

  const activePartIndex = partId
    ? parts.findIndex(part => part.id === partId)
    : parts.findIndex(part => !!part.steps.find(step => step.id === stepId));
  const activePart = parts[activePartIndex];
  const prevPart = parts[activePartIndex - 1];
  const nextPart = parts[activePartIndex + 1];

  if (partId) {
    // prevPath
    if (prevPart) {
      if (prevPart.steps.length) {
        nav.prevPath = getStepPath(prevPart.steps[prevPart.steps.length - 1].id);
      } else {
        nav.prevPath = getPartPath(prevPart.id);
      }
    }

    // nextPath
    if (activePart.steps.length) {
      nav.nextPath = getStepPath(activePart.steps[0].id);
    } else if (nextPart) {
      nav.nextPath = getPartPath(nextPart.id);
    }
  } else if (stepId) {
    const activeStepIndex = activePart.steps.findIndex(step => step.id === stepId);

    // prevPath
    const prevStep = activePart.steps[activeStepIndex - 1];
    if (prevStep) {
      nav.prevPath = getStepPath(prevStep.id);
    } else {
      nav.prevPath = getPartPath(activePart.id);
    }

    // nextpath
    const nextStep = activePart.steps[activeStepIndex + 1];
    if (nextStep) {
      nav.nextPath = getStepPath(nextStep.id);
    } else if (nextPart) {
      nav.nextPath = getPartPath(nextPart.id);
    }
  }
  return { payload: nav };
};