import React from 'react';
import { Link } from 'react-router-dom';
import { Project as ProjectType } from '../../../app/model/ProjectModel';
import { Map } from './Map';
import { ProjectProgress } from './projectProgress/ProjectProgress';

import './ProjectBlock.scss';
import { ArrowLink } from '../../common/arrowLink/ArrowLink';

type Props = {
  project: ProjectType
};

export const ProjectBlock: React.FC<Props> = ({ project }) => {
  return (
    <Link to={`/projects/${project.uuid}`} className="ProjectBlock">
      <Map className="ProjectBlock__map" location={project.coords}/>
      <ProjectProgress className="ProjectBlock__progress" progress={project.progress}/>
      <div className="ProjectBlock__info">
        {project.lastContract && <span className="ProjectBlock__contract-name mb-2">{project.lastContract}</span>}
        <span className="Heading Heading_level_3 Heading_primary mb-6">{project.name}</span>
        <ArrowLink>Подробнее</ArrowLink>
      </div>
    </Link>
  );
}