import { axiosInstance } from ".";
import { File } from "./ObjectStorageModel";

export type TimelineContract = {
  id: string
  name: string
  number: string
  signDate: string
  projectUuid: string
};

export type TimelineStage = {
  name: string
  number: string
  type: string
  description: string
  deadline: string
};

export type TimelineContractAct = {
  id: string
  file: File[]
  projectUuid: string
};

export enum TimelineEventTypes {
  CONTRACT = 'contract',
  STAGE = 'stage',
  CONTRACT_ACT = 'contractAct',
  BRIEFING = 'briefing',
  MESSAGE = 'message'
};

export type TimelineEvent = {
  id: string
  type: TimelineEventTypes
  data: any
  createdAt: string
  projectUuid: string
  stageId: string
  contractId: string
  contractActId: string
  clientUuid: string
};

export class ProjectTimelineModel {

  static async fetchContractsByProject(projectUuid: string): Promise<TimelineContract[]> {
    return axiosInstance.get(`/management/pub/timeline-contracts/${projectUuid}`).then(res => res.data);
  }

  static async fetchStagesByProject(projectUuid: string): Promise<TimelineStage[]> {
    return axiosInstance.get(`/management/pub/timeline-stages/${projectUuid}`).then(res => res.data);
  }

  static async fetchContractActsByProject(projectUuid: string): Promise<TimelineContractAct[]> {
    return axiosInstance.get(`/management/pub/timeline-contract-acts/${projectUuid}`).then(res => res.data);
  }

  static async fetchEventsByProject(projectUuid: string): Promise<TimelineEvent[]> {
    return axiosInstance.get(`/management/pub/timeline-events/${projectUuid}`).then(res => res.data);
  }

  static async createEvent(value: Partial<TimelineEvent>) {
    return axiosInstance.post(`/management/pub/timeline-events`, value).then(res => res.data);
  }

}