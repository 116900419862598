import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { Answer, BriefingModel, FetchAnswersParams } from "../../../app/model/BriefingModel";
import { RootState } from "../../../app/store";

export const fetchAnswers = createAsyncThunk('briefing/answer',
  async (params: FetchAnswersParams) => await BriefingModel.fetchAnswers(params)
);

export const updateAnswer = createAsyncThunk('briefing/updateAnswer',
  async (answer: Answer) => await BriefingModel.updateAnswer(answer)
);

const answerEntityAdapter = createEntityAdapter<Answer>({
  selectId: entity => entity.questionId
});

const answerSlice = createSlice({
  name: 'answer',
  initialState: answerEntityAdapter.getInitialState({
    isLoading: false
  }),
  reducers: { },
  extraReducers: builder => {
    builder.addCase(fetchAnswers.fulfilled, (state, action) => {
      answerEntityAdapter.upsertMany(state, action.payload);
    });

    builder.addCase(updateAnswer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateAnswer.fulfilled, (state, action) => {
      state.isLoading = false;
      answerEntityAdapter.upsertOne(state, action.payload);
    });
  }
});

export const selectIsLoading = (state: RootState) => state.briefing.answer.isLoading;

export const {
  selectById: selectAnswerById
} = answerEntityAdapter.getSelectors((state: RootState) => state.briefing.answer);

export const selectAnswersByStep = createSelector(
  (state: RootState) => state.briefing.answer,
  (_: any, stepId: string) => stepId,
  (entityState, stepId) => entityState.ids
    .filter(id => {
      return (entityState.entities[id] as Answer).stepId === stepId
    })
    .map(id => entityState.entities[id])
);

export const answerReducer = answerSlice.reducer;