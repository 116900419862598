import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ProfilePage } from './features/profile/ProfilePage';
import { ProjectsPage } from './features/project/ProjectsPage';
import { Menu } from './features/menu/Menu';
import { ProjectPage } from './features/project/projectPage/ProjectPage';
import { SocketWrapper } from './SocketWrapper';
import { PrivateRoutes } from './features/auth/PrivateRoutes';
import { Preloader } from './features/common/preloader/Preloader';
import { AuthenticationKey } from './features/auth/AuthenticationKey';
import { LoginPage } from './features/auth/LoginPage';
import { ProfileWrapper } from './features/auth/ProfileWrapper';
import { PasswordLoginPage } from './features/auth/PasswordLoginPage';
import { Helmet } from "react-helmet";
import { SetFixedVh } from './features/common/setFixedVh/SetFixedVh';

const BriefingPage = React.lazy(() => import('./features/briefing/briefingPage/BriefingPage'));

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Личный кабинет Art Studio HOUSE</title>
      </Helmet>
      <SetFixedVh/>
      <Menu/>
      <Switch>
        <Route path="/authentication-key/:key" component={AuthenticationKey}/>
        <Route path="/login" component={LoginPage}/>
        <Route path="/password-login" component={PasswordLoginPage}/>
        <PrivateRoutes>
          <ProfileWrapper>
            <SocketWrapper>
              <Route exact path="/" component={ProjectsPage}/>
              <Route path="/profile" component={ProfilePage}/>
              <Route path="/projects/:projectUuid" component={ProjectPage}/>
              <Suspense fallback={<Preloader primary centering/>}>
                <Route path="/briefings/:projectUuid" component={BriefingPage}/>
              </Suspense>
            </SocketWrapper>
          </ProfileWrapper>
        </PrivateRoutes>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
