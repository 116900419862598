import { useEffect, useState } from "react";
import { downloadFile } from "../../../app/helpers/downloadFile";
import { ObjectStorageModel, File } from "../../../app/model/ObjectStorageModel";

type Props = {
  name: string
  fileName?: string
  render: (props: {
    handleDownload: () => void
    handleDelete: () => void
    file: File | null
    isDeleting: boolean
  }) => React.ReactElement
  deleted?: (key: string) => void
};

export const PrivateFile: React.FC<Props> = ({
  name,
  fileName,
  render,
  deleted = () => {},
}) => {
  const [ file, setFile ] = useState<File | null>(null);
  const [ isDeleting, setIsDeleting ] = useState(false);
  const handleDownload = () => {
    ObjectStorageModel.getFileLink(name)
      .then(link => {
        downloadFile(fileName || (file as File).originalName, link);
      });
  };
  const handleDelete = () => {
    setIsDeleting(true);

    ObjectStorageModel.delete(name)
      .finally(() => {
        setIsDeleting(false);
        deleted(name);
      });
  };

  useEffect(() => {
    if (!name) { return; }

    ObjectStorageModel.headFile(name).then(file => {
      setFile(file);
    });
  }, [ name ]);

  return render({ handleDownload, handleDelete, file, isDeleting });
}