import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menuOpen: false,
    show: true
  },
  reducers: {
    toggleMenu(state) {
      state.menuOpen = !state.menuOpen;
    },
    closeMenu(state) {
      state.menuOpen = false;
    },
    showMenu(state) {
      state.show = true;
    },
    hideMenu(state) {
      state.show = false;
    }
  }
});

export const menuReducer = menuSlice.reducer;

export const { toggleMenu, closeMenu, showMenu, hideMenu } = menuSlice.actions;

export const selectMenuOpen = (state: RootState) => state.menu.menuOpen;
export const selectMenuShow = (state: RootState) => state.menu.show;