import { useState } from "react";
import { ObjectStorageModel, File } from "../../../app/model/ObjectStorageModel";
import { Preloader } from "../preloader/Preloader";
import cn from 'classnames';

import './FileUpload.scss';

type Props = {
  uploaded: (files: File[]) => void
  multiple?: boolean
  className?: any
  labelClassName?: any
};

export const FileUpload: React.FC<Props> = ({ multiple = false, className, labelClassName, uploaded }) => {
  const [ labels, setLabels ] = useState<null | string[]>(null);
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.nativeEvent.target as HTMLInputElement;
    const { files } = target;
    const filesNames = [];

    if (!target || !files) { return null; }

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      filesNames.push(files[i].name);
    }

    setLabels(filesNames);

    ObjectStorageModel.upload(formData)
      .then(files => {
        target.value = '';
        setLabels(null);
        uploaded(files);
      });
  }

  const label = labels
    ? labels.join(', ')
    : 'Загрузить файл...';

  return (
    <label className={cn('FileUpload', className)}>
      <input
        disabled={!!labels}
        className="hidden"
        multiple={multiple}
        type="file"
        onChange={handleChange}
      />
      <div className={cn('FileUpload__label',  labelClassName)} title={label}>
        <span className="FileUpload__label-value">
          {label}
        </span>
        <div className="FileUpload__icon">
          {
            labels
              ? <Preloader/>
              : <svg className="FileUpload__clip"><use xlinkHref="#icon-clip"/></svg>
          }
        </div>
      </div>
    </label>
  );
};