import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Project } from "../../../app/model/ProjectModel";
import { RootState } from "../../../app/store";
import { Preloader } from "../../common/preloader/Preloader";
import { fetchProject, selectProjectById } from "../projectsSlice";
import { ProjectHeadline } from "./projectHeadline/ProjectHeadline";
import { Timeline } from "./timeline/Timeline";
import { Helmet } from "react-helmet";

import './ProjectPage.scss';

const useContentGuard = (projectUuid: string) => {
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => selectProjectById(state, projectUuid));

  useEffect(() => {
    if (project) { return; }

    dispatch(fetchProject(projectUuid));
  }, [ project, projectUuid, dispatch ]);

  return !!project;
};

export const ProjectPage = () => {
  const { projectUuid } = useParams<{projectUuid: string}>();
  const isContentLoaded = useContentGuard(projectUuid);
  const project = useSelector((state: RootState) => selectProjectById(state, projectUuid)) as Project;

  if (!isContentLoaded) {
    return <Preloader primary centering/>;
  }

  return (
    <div className="ProjectPage">
      <Helmet>
        <title>{project.name}</title>
      </Helmet>
      <ProjectHeadline title={project.name}/>
      <Timeline/>
    </div>
  );
};