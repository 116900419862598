import { useState } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { AuthModel } from '../../app/model/AuthModel';
import { PageLayout } from '../common/pageLayout/PageLayout';
import { EnterCode } from './EnterCode';
import { EnterPhone } from './EnterPhone';
import { Helmet } from "react-helmet";

import './LoginPage.scss';

export const LoginPage = () => {
  const location = useLocation();
  const history = useHistory();
  const state = location.state as any;
  const [ isShowCode, setIsShowCode ] = useState(false);
  const [ phone, setPhone ] = useState('');
  const handleSuccess = () => history.push(state && state.referrer ? state.referrer : '/');
  const handleBack = () => setIsShowCode(false);
  const handleSend = (phone: string) => {
    setIsShowCode(true);
    setPhone(phone);
  };

  if (AuthModel.isAuthenticated()) {
    return <Redirect to="/"/>;
  }

  return (
    <PageLayout>
      <Helmet>
        <title>Вход в личный кабинет Art Studio HOUSE</title>
      </Helmet>
      <div className="LoginPage Wrap">
        <h1 className="Heading Heading_level_1 mb-4">Личный кабинет</h1>
        <p className="Paragraph mb-8">Инструмент для работы с Art Studio HOUSE</p>
        <div className="LoginPage__form">
          {!isShowCode && <EnterPhone onSend={handleSend}/>}
          {isShowCode && <EnterCode phone={phone} onBack={handleBack} onSuccess={handleSuccess}/>}
        </div>
      </div>
    </PageLayout>
  );
};