import cn from 'classnames';

import './PageLayout.scss';

type Props = {
  children: any,
  withoutPadding?: boolean
  className?: any
};

export const PageLayout: React.FC<Props> = ({ children, className, withoutPadding }) => (
  <main
    className={cn('PageLayout', className, { 'PageLayout_without-padding': withoutPadding })}
  >
    {children}
  </main>
);