import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Project } from '../../app/model/ProjectModel';
import { RootState } from '../../app/store';
import { PageLayout } from '../common/pageLayout/PageLayout';
import { Preloader } from '../common/preloader/Preloader';
import { ProjectBlock } from './projectBlock/ProjectBlock';
import { selectProjects, fetchProjects, selectProjectsLoaded } from './projectsSlice';
import { Helmet } from "react-helmet";

const useContentGuard = () => {
  const dispatch = useDispatch();
  const loaded = useSelector((state: RootState) => selectProjectsLoaded(state));

  useEffect(() => {
    if (loaded) { return; }

    dispatch(fetchProjects());
  }, [loaded, dispatch]);

  return loaded;
};

export const ProjectsPage = () => {
  const projects = useSelector((state: RootState) => selectProjects(state)) as Project[];
  const isContentLoaded = useContentGuard();

  if (!isContentLoaded) {
    return <Preloader primary centering/>;
  }

  return (
    <PageLayout>
      <Helmet>
        <title>Проекты</title>
      </Helmet>
      <main className="Wrap">
        <h1 className="Heading Heading_level_1 mb-12">Проекты</h1>
        <div>
          {projects.map(project => (
            <div key={project.uuid} className="mb-10">
              <ProjectBlock project={project}/>
            </div>
          ))}
        </div>
      </main>
    </PageLayout>
  );
};