import { Link } from 'react-router-dom';
import cn from 'classnames';

import './Button.scss';

type Props = {
  to?: string
  children: React.ReactNode
  className?: any
  filled?: boolean
  border?: boolean
  secondary?: boolean
  icon?: string
  upper?: boolean
  arrow?: boolean
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  onClick?: any
};

export const Button: React.FC<Props> = ({
    to,
    children,
    arrow,
    className, filled, border, secondary, upper,
    icon,
    ...otherProps
  }) => {
  const mods = [
    'Button',
    className,
    {
      'Button_filled': filled,
      'Button_border': border,
      'Button_secondary': secondary,
      'Button_upper': upper,
    }
  ];

  const iconElem = icon && <svg className="Button__icon"><use xlinkHref={`#icon-${icon}`}/></svg>;

  if (to) {
    return (
      <Link className={cn(mods)} to={to}>
        {iconElem}
        {children}
      </Link>
    );
  } else {
    return (
      <button className={cn(mods)} {...otherProps}>
        {iconElem}
        {children}
      </button>
    );
  }
}