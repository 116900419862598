import { format } from 'date-fns';
import { axiosInstance } from '.';
import { File } from './ObjectStorageModel';

export type QuestionType = 
  'text'
  | 'number'
  | 'option'
  | 'option_with_description'
  | 'option_with_image'
  | 'drop_down_list'
  | 'date'
  | 'toggle'
  | 'file'
  | 'slider'
  | 'comment'
  | 'repeater';

export type Question = {
  id: string
  question: string
  hint: string
  stepId: string
  type: QuestionType
  settings: any
};

export type Step = {
  id: string
  name: string
  description: string
  cover: File[]
  sort: number
};

export type Answer = {
  uuid: string
  data: any
  questionId: string
  stepId: string
  projectUuid: string
  clientUuid?: string
  updatedAt?: string
  isValid: boolean
};

export type BriefingContentStep = {
  id: string
  name: string
  progress: number
};
export type BriefingContentPart = {
  id: string
  name: string
  steps: BriefingContentStep[]
};
export type BriefingContent = {
  projectUuid: string
  parts: BriefingContentPart[]
};

export type Part = {
  id: string
  name: string
  description: string
};

export type FetchAnswersParams = {
  stepId: string
  projectUuid: string
};

export type FetchPartsParams = {
  partId: string
  projectUuid: string
};

export enum BriefingStatuses {
  CLOSED = 'closed',
  PROGRESS = 'progress',
  CHECK = 'check',
  DONE = 'done'
};
export type Briefing = {
  projectUuid: string
  status: BriefingStatuses
  position: string
  fileName: string
};

export class BriefingModel {

  static fetchBriefing(projectUuid: string): Promise<Briefing> {
    return axiosInstance.get(`briefing/pub/briefings/${projectUuid}`).then(res => res.data);
  }

  static setStatusCheckBriefing(projectUuid: string): Promise<Briefing> {
    return axiosInstance.post(`briefing/pub/briefings/${projectUuid}/check`).then(res => res.data);
  }

  static fetchContent(projectUuid: string): Promise<BriefingContent> {
    return axiosInstance.get(`briefing/pub/briefing-contents/${projectUuid}`).then(res => res.data);
  }

  static fetchPart(params: FetchPartsParams): Promise<Part> {
    return axiosInstance.get(`briefing/pub/parts`, { params }).then(res => res.data);
  }

  static fetchStep(stepId: string): Promise<Step> {
    return axiosInstance.get(`briefing/pub/steps/${stepId}`).then(res => res.data);
  }

  static fetchQuestions(stepId: string): Promise<Question[]> {
    return axiosInstance.get(`briefing/pub/questions`, {params: { stepId }}).then(res => res.data);
  }

  static fetchAnswers(params: FetchAnswersParams): Promise<Answer[]> {
    return axiosInstance.get(`briefing/pub/answers`, { params }).then(res => res.data);
  }

  static fetchProgress(projectUuid: string): Promise<{progress: number}> {
    return axiosInstance.get(`briefing/progress/global/${projectUuid}`).then(res => res.data);
  }

  static saveAnswer(data: Partial<Answer>): Promise<Answer> {
    return axiosInstance.post('briefing/pub/answers', data).then(res => res.data);
  }

  static updateAnswer(answer: Answer): Promise<Answer> {
    return axiosInstance.patch('briefing/pub/answers', answer).then(res => res.data);
  }

  static downloadBriefing(projectUuid: string): Promise<{token: string}> {
    return axiosInstance.get(`briefing/download/${projectUuid}`).then(res => res.data);
  }

  static getBriefingFileName(): string {
    return `${format(new Date(), 'yyyyMMdd')} ASH PM Briefing.pdf`;
  }

}