import { useState } from 'react';
import { FileUpload } from '../../../../common/fileUpload/FileUpload';
import { SendButton } from './sendButton/SendButton';
import { File } from '../../../../../app/model/ObjectStorageModel';
import { AttachmentList } from './AttachmentList/AttachmentList';
import { ProjectTimelineModel } from '../../../../../app/model/ProjectTimelineModel';
import { useParams } from 'react-router-dom';

import './FeedCompose.scss';

const initEventData = {
  attachments: [] as File[]
};

export const FeedCompose = () => {
  const [ isProgress, setIsProgress ] = useState(false);
  const [ eventData, setEventData ] = useState<typeof initEventData>(initEventData);
  const { projectUuid } = useParams() as {projectUuid: string};

  const handleBeginSending = () => {
    if (!eventData.attachments.length) { return; }

    setIsProgress(true);

    ProjectTimelineModel.createEvent({
      projectUuid,
      data: eventData
    }).then(() => {
      setEventData(initEventData);
      setIsProgress(false);
    });
  };
  const handleAddAttachment = (attachments: any[]) => {
    setEventData(data => (
      {...data, attachments: data.attachments.concat(attachments)}
    ));
  };
  const handleDeleteAttachment = (name: string) => {
    setEventData(data => (
      {...data, attachments: data.attachments.filter(a => a.name !== name)}
    ));
  }

  return (
    <div className="FeedCompose">
      <div className="Wrap">
        <div className="FeedCompose__content">
          <FileUpload
            uploaded={handleAddAttachment}
            className="FeedCompose__field"
            labelClassName="FeedCompose__attachment"
            multiple
          />
          <SendButton progress={isProgress} onClick={handleBeginSending}/>
        </div>
      </div>
      <div className="FeedCompose__list">
        <div className="Wrap">
          <AttachmentList
            files={eventData.attachments}
            deleted={handleDeleteAttachment}
            className="mt-6"
          />
        </div>
      </div>
    </div>
  );
}