import cn from 'classnames';

import './Preloader.scss';

type Props = {
  size?: '1' | '2'
  primary?: boolean
  centering?: boolean
  inline?: boolean
};

export const Preloader: React.FC<Props> = ({ size = '1', primary, centering, inline }) => {
  const className = cn(
    'Preloader',
    `Preloader_size_${size}`,
    {
      'Preloader_primary': primary,
      'Preloader_centering': centering,
      'Preloader_inline': inline
    }
  );
  return (
    <div className={className}>
      <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M56.567 33.449a28.5 28.5 0 11-7.566-24.747l-2.665 2.574a24.795 24.795 0 106.582 21.53l3.649.643z" fill="currentColor"/></svg>
    </div>
  );
};
