import ReactDOM from 'react-dom';

import './Modal.scss';

export const ModalContainer = () => (
  <div id="modal-container"/>
);

type Props = {
  title?: string
  children: any
  onClose?: any
  open?: boolean
};

export const Modal: React.FC<Props> = ({ title, children, open = false, onClose }) => {
  const handleClickInnerContainer = (event: any) => event.stopPropagation();
  const modalContainer = document.querySelector('#modal-container') as HTMLElement;

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="Modal">
      <div className="Modal__overlay" onClick={onClose}>
        <div className="Modal__container" onClick={handleClickInnerContainer}>
          <header className="Modal__header">
            <span className="Modal__title">{title}</span>
            <button className="Modal__close" onClick={onClose}>
              <svg><use xlinkHref="#icon-times"/></svg>
            </button>
          </header>
          <div className="Modal__content">{children}</div>
        </div>
      </div>
    </div>,
    modalContainer
  );
};