import { File } from "../../../../../../../app/model/ObjectStorageModel";
import { TimelineEvent } from "../../../../../../../app/model/ProjectTimelineModel";
import { Preloader } from "../../../../../../common/preloader/Preloader";
import { PrivateFile } from "../../../../../../common/privateFile/PrivateFile";
import { EventInfo } from "../eventInfo/EventInfo";
import { UserEventLayout } from "../userEventLayout/UserEventLayout";

import './MessageType.scss';

type AttachmentListProps = {
  files: File[]
};

const AttachmentList: React.FC<AttachmentListProps> = ({ files }) => (
  <ul className="MessageType__attachment-list">
    {files.map(f => (
      <li key={f.name}>
        <PrivateFile name={f.name} render={({ file, handleDownload }) => (
          file
            ? <button className="Link" onClick={handleDownload}>{file.originalName}</button>
            : <Preloader inline/>
        )}/>
      </li>
    ))}
  </ul>
);

type Props = {
  event: TimelineEvent
};

export const MessageType: React.FC<Props> = ({ event }) => {
  const { message, attachments } = event.data;

  const content = (
    <>
      <EventInfo className="mb-1" date={event.createdAt} clientUuid={event.clientUuid}/>
      {!!message && <div dangerouslySetInnerHTML={{ __html: message }}/>}
      {!!attachments &&  <AttachmentList files={event.data.attachments}/>}
    </>
  );

  if (!event.clientUuid) {
    return (
      <UserEventLayout icon="file">
        {content}
      </UserEventLayout>
    );
  } else {
    return content;
  }
}