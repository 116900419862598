import { format } from "date-fns";
import { TimelineEvent, TimelineContract } from "../../../../../../../app/model/ProjectTimelineModel";
import { UserEventLayout } from "../userEventLayout/UserEventLayout";
import { EventInfo } from "../eventInfo/EventInfo";

type Props = {
  event: TimelineEvent,
};

export const ContractType: React.FC<Props> = ({ event }) => {
  const contract = event.data as TimelineContract;
  const signDate = format(new Date(contract.signDate), 'dd.MM.yyyy');

  return (
    <UserEventLayout icon="case">
      <EventInfo date={event.createdAt} className="mb-1"/>
      <span className="Event__title mb-2">
        Добавлен договор<br/>«{contract.name}»
      </span>
      <p className="Paragraph">
        Начаты работы по Договору<br/>№{contract.number} от {signDate} г.
      </p>
    </UserEventLayout>
  );
};