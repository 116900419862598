import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BriefingModel } from "../../../app/model/BriefingModel";
import { RootState } from "../../../app/store";

export const fetchProgress = createAsyncThunk('briefing/progress',
  async (projectUuid: string) => await BriefingModel.fetchProgress(projectUuid)
);

const progressSlice = createSlice({
  name: 'progress',
  initialState: 0,
  reducers: {
    setProgress(state, action) {
      return action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchProgress.fulfilled, (state, action) => action.payload.progress);
  }
});

export const { setProgress } = progressSlice.actions;

export const selectProgress = (state: RootState) => state.briefing.progress;

export const progressReducer = progressSlice.reducer;