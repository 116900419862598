import './ArrowLink.scss';

type Props = {
  children: any
};

export const ArrowLink: React.FC<Props> = ({ children }) => {
  return (
    <span className="ArrowLink">
      {children}
      <svg><use xlinkHref="#icon-arrow-right"/></svg>
    </span>
  );
}