import { File } from "../../../../../../../app/model/ObjectStorageModel";
import { Preloader } from "../../../../../../common/preloader/Preloader";
import { PrivateFile } from "../../../../../../common/privateFile/PrivateFile";

import './Attachment.scss';

type Props = {
  file: File,
  deleted?: (uuid: string) => void
};

export const Attachment: React.FC<Props> = ({ file, deleted }) => {
  return (
    <PrivateFile name={file.name} deleted={deleted} render={({
        handleDownload,
        handleDelete,
        isDeleting,
        file
      }) => (
        <div className="Attachment">
          <button className="Attachment__name" onClick={handleDownload}>
            {
              file
                ? file.originalName
                : <Preloader/>
            }
          </button>
          <button
            className="Attachment__delete-btn"
            type="button"
            onClick={() => handleDelete()}
            title="Удалить"
          >
            {
              isDeleting
                ? <Preloader/>
                : <svg><use xlinkHref="#icon-times"/></svg>
            }
          </button>
        </div>
      )}
    />
  );
};