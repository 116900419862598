import React from "react";
import { AuthModel } from "../../app/model/AuthModel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button } from "../common/button/Button";
import InputMask from 'react-input-mask';
import * as yup from 'yup';

type Props = {
  onSend: (phone: string) => void
};

export const EnterPhone: React.FC<Props> = ({ onSend }) => {
  return (
    <Formik
      initialValues={{ phone: '' }}
      validationSchema={yup.object().shape({
        phone: yup.string().matches(/\+7 \d\d\d \d\d\d-\d\d-\d\d/, 'Введите действительный номер телефона').required('Обязательное поле')
      })}
      onSubmit={async (values, formikContext) => {
        try {
          await AuthModel.loginPhone(values);
        } catch (err) {
          return formikContext.setErrors({phone: 'Неправильный номер телефона'});
        }

        onSend(values.phone);
      }}
    >
      {({ isSubmitting, handleChange, handleBlur }) => (
        <Form>
          <div className="mb-6">
            <label className="Label mb-2">Номер телефона</label>
            <Field>
              {(field: any) => (
                <InputMask
                  {...field}
                  className="Input"
                  type="tel"
                  placeholder="+7"
                  aria-label="Телефон" 
                  mask="+7 999 999-99-99"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
            </Field>
            <ErrorMessage name="phone">
              {msg => <span className="ErrorMessage mt-1">{msg}</span>}
            </ErrorMessage>
          </div>
          <Button type="submit" border disabled={isSubmitting}>Продолжить</Button>
        </Form>
      )}
    </Formik>
  );
};