import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Project, ProjectModel } from "../../app/model/ProjectModel";
import { RootState } from "../../app/store";

export const fetchProjects = createAsyncThunk('projects/fetchProjects',
  async () => await ProjectModel.getProjects()
);

export const fetchProject = createAsyncThunk('projects/fetchProject',
  async (projectUuid: string) => await ProjectModel.getProject(projectUuid)
);

const projectEnittyAdapter = createEntityAdapter<Project>({
  selectId: entity => entity.uuid
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState: projectEnittyAdapter.getInitialState({
    loaded: false
  }),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.loaded = true;
      projectEnittyAdapter.upsertMany(state, action);
    });
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      projectEnittyAdapter.upsertOne(state, action);
    });
  }
});

export const projectsReducer = projectsSlice.reducer;

export const {
  selectAll: selectProjects,
  selectById: selectProjectById,
} = projectEnittyAdapter.getSelectors((state: RootState) => state.projects);

export const selectProjectsLoaded = (state: RootState) => state.projects.loaded;
