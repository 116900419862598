import { Preloader } from '../../../../../common/preloader/Preloader';

import './SendButton.scss';

type Props = {
  progress?: boolean,
  onClick?: any
};

export const SendButton: React.FC<Props> = ({ progress, onClick }) => {
  return (
    <button
      disabled={progress}
      className="SendButton"
      onClick={onClick}
    >
      {progress
        ? <Preloader/>
        : <svg><use xlinkHref="#icon-send"/></svg>
      }
    </button>
  )
};