const ASSECC_TOKEN_NAME = 'ASH_ACCESS_TOKEN';
const REFRESH_TOKEN_NAME = 'ASH_REFRESH_TOKEN';

export type Tokens = {
  accessToken: string,
  refreshToken: string
}

export class TokensManager {

  static setTokens(tokens: Tokens): void {
    TokensManager.setAccessToken(tokens.accessToken);
    TokensManager.setRefreshToken(tokens.refreshToken);
  }

  static dropTokens(): void {
    localStorage.removeItem(ASSECC_TOKEN_NAME);
    localStorage.removeItem(REFRESH_TOKEN_NAME);
  }

  static getAccessToken(): string | null {
    return localStorage.getItem(ASSECC_TOKEN_NAME);
  }

  static getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  static setAccessToken(value: string): void {
    localStorage.setItem(ASSECC_TOKEN_NAME, value);
  }

  static setRefreshToken(value: string): void {
    return localStorage.setItem(REFRESH_TOKEN_NAME, value);
  }

}