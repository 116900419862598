import React from 'react';
import './ProjectProgress.scss';
import cn from 'classnames';

type Props = {
  progress: number,
  className?: any
};

export const ProjectProgress: React.FC<Props> = ({ progress, className }) => {
  const radius = 48;
  const c = Math.PI * (radius * 2);
  const percent = ((100 - progress) / 100) * c;

  return (
    <div className={cn('ProjectProgress', className)}>
      <svg className="ProjectProgress__circles" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle r={radius} cx="50" cy="50" fill="transparent" strokeDasharray="301.59" strokeDashoffset="0"/>
        <circle r={radius} cx="50" cy="50" fill="transparent" strokeDasharray="301.59" strokeDashoffset={percent}/>
      </svg>
      <div className="ProjectProgress__percent">{progress}%</div>
    </div>
  );
}