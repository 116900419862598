import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { ProjectTimelineModel, TimelineEvent } from "../../../app/model/ProjectTimelineModel";
import { RootState } from "../../../app/store";

export const fetchEventsByProject = createAsyncThunk('project/fetchEventsByProject',
  async (projectUuid: string) => await ProjectTimelineModel.fetchEventsByProject(projectUuid)
);

const timelineEventEnittyAdapter = createEntityAdapter<TimelineEvent>();

const eventSlice = createSlice({
  name: 'event',
  initialState: timelineEventEnittyAdapter.getInitialState(),
  reducers: {
    upsertOneEvent: (state, action) => {
      timelineEventEnittyAdapter.upsertOne(state, action);
    },
    removeOneEvent: (state, action) => {
      timelineEventEnittyAdapter.removeOne(state, action);
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchEventsByProject.fulfilled, (state, action) => {
      timelineEventEnittyAdapter.upsertMany(state, action);
    });
  }
});

export const timelineEventReducer = eventSlice.reducer;

export const {
  upsertOneEvent,
  removeOneEvent
} = eventSlice.actions;

export const {
  selectById: selectTimelineEventById,
} = timelineEventEnittyAdapter.getSelectors((state: RootState) => state.project.event);

export const selectEventsByProject = createSelector(
  (state: RootState) => state.project.event,
  (_: any, projectUuid: string) => projectUuid,
  (stage, projectUuid) => stage.ids
    .filter(id => (stage.entities[id] as TimelineEvent).projectUuid === projectUuid)
    .map(id => stage.entities[id] as TimelineEvent)
);

export const selectLastStageEvent = (stage: RootState): TimelineEvent => {
  const stageEvents = stage.project.event.ids
    .filter(id => (stage.project.event.entities[id] as TimelineEvent).type === 'stage')
    .map(id => stage.project.event.entities[id] as TimelineEvent);

  return stageEvents[stageEvents.length - 1];
}