import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { BriefingModel, Part, FetchPartsParams } from "../../../app/model/BriefingModel";
import { RootState } from "../../../app/store";

export const fetchPart = createAsyncThunk('briefing/part',
  async (params: FetchPartsParams) => await BriefingModel.fetchPart(params)
);

const partEntityAdapter = createEntityAdapter<Part>();

const partSlice = createSlice({
  name: 'part',
  initialState: partEntityAdapter.getInitialState(),
  reducers: { },
  extraReducers: builder => {
    builder.addCase(fetchPart.fulfilled, (state, action) => {
      partEntityAdapter.upsertOne(state, action.payload);
    });
  }
});

export const {
  selectById: selectPartById
} = partEntityAdapter.getSelectors((state: RootState) => state.briefing.part);

export const partReducer = partSlice.reducer;