import { useDispatch, useSelector } from 'react-redux';
import { Briefing, BriefingStatuses } from '../../../../../../../../app/model/BriefingModel';
import { TimelineEvent, TimelineStage } from '../../../../../../../../app/model/ProjectTimelineModel';
import { RootState } from '../../../../../../../../app/store';
import { selectBriefingById, upsertOneBriefing } from '../../../../../../../briefing/store/briefingSlice';
import { EventHeadline } from '../../eventHeadline/EventHeadline';
import { EventInfo } from '../../eventInfo/EventInfo';
import { ProgressInfo } from './ProgressInfo';
import { useEffect } from 'react';
import { useSocketRoom } from '../../../../../../../../SocketWrapper';
import { UserEventLayout } from '../../userEventLayout/UserEventLayout';
import { ProgressContent } from './ProgressContent';
import { CheckContent } from './CheckContent';
import { DoneContent } from './DoneContent';

import './BriefingType.scss';

type Props = {
  event: TimelineEvent
  active: boolean
};

export const BriefingType: React.FC<Props> = ({ event, active }) => {
  const dispatch = useDispatch();
  const { projectUuid } = event;
  const stage = event.data as TimelineStage;
  const briefing = useSelector((state: RootState) => selectBriefingById(state, projectUuid)) as Briefing;
  const socketRoom = useSocketRoom(`briefings/${projectUuid}/update`);

  useEffect(() => {
    socketRoom.on('update', (briefing: Briefing) => {
      dispatch(upsertOneBriefing(briefing));
    });
  }, [ socketRoom, dispatch ]);

  return (
    <UserEventLayout number={stage.number}>
      <EventInfo date={event.createdAt} className="mb-1"/>
      <span className="Event__title mb-3">
        Запущен этап {stage.number}. «{stage.name}»
      </span>
      <EventHeadline deadline={stage.deadline} className="mb-4" done={!active}>
        <ProgressInfo projectUuid={projectUuid}/>
      </EventHeadline>
      {
        briefing.status === BriefingStatuses.PROGRESS &&
        <ProgressContent projectUuid={projectUuid} description={stage.description}/>
      }
      {
        briefing.status === BriefingStatuses.CHECK &&
        <CheckContent/>
      }
      {
        briefing.status === BriefingStatuses.DONE &&
        <DoneContent fileName={briefing.fileName}/>
      }
    </UserEventLayout>
  );
}