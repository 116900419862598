import React from 'react';
import { NavLink  } from 'react-router-dom';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import { closeMenu, selectMenuOpen } from '../menuSlice';

import './MenuContent.scss'
import { LogoutButton } from './LogoutButton';

const menuItems = [
  {
    title: 'Мои проекты',
    path: '/',
    exact: true
  },
  {
    title: 'Личные данные',
    path: '/profile',
    exact: false
  }
];

export const MenuContent = () => {
  const dispatch: AppDispatch = useDispatch();
  const isMenuOpen = useSelector(selectMenuOpen);
  const handleLinkClick = () => dispatch(closeMenu());
  const windowHeight = window.innerHeight;

  return (
    <div
      className={cn('MenuContent', { 'MenuContent_open': isMenuOpen })}
      style={{height: windowHeight}}
    >
      <div className="Wrap MenuContent__content">
        <ul className={cn('MenuContent__list')}>
          {
            menuItems.map(item => (
              <li key={item.title} className="MenuContent__item">
                <NavLink
                  to={item.path}
                  activeClassName="MenuContent__link_active"
                  exact={item.exact}
                  onClick={handleLinkClick}
                  className="MenuContent__link Heading Heading_level_1"
                >
                  {item.title}
                </NavLink>
              </li>
            ))
          }
        </ul>
        <div className="MenuContent__footer">
          <LogoutButton/>
        </div>
      </div>
    </div>
  );
};