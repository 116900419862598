import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TimelineEvent } from '../../../../../app/model/ProjectTimelineModel';
import { RootState } from '../../../../../app/store';
import { useSocketRoom } from '../../../../../SocketWrapper';
import { removeOneEvent, selectEventsByProject, upsertOneEvent } from '../../../store/timelineEventSlice';
import { Event } from './event/Event';

import './Events.scss';

export const Events = () => {
  const dispatch = useDispatch();
  const { projectUuid } = useParams<{projectUuid: string}>();
  const events = useSelector(((stage: RootState) => selectEventsByProject(stage, projectUuid)));
  const socketUpdateRoom = useSocketRoom(`projects/${projectUuid}/timelineUpdate`);
  const socketDeleteRoom = useSocketRoom(`projects/${projectUuid}/timelineDelete`);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    socketUpdateRoom.on('timelineUpdate', (event: TimelineEvent) =>
      dispatch(upsertOneEvent(event))
    );
    socketDeleteRoom.on('timelineDelete', (event: TimelineEvent) =>
      dispatch(removeOneEvent(event.id))
    );
  }, [ socketUpdateRoom, socketDeleteRoom, dispatch ]);

  return (
    <div ref={listRef} className="Events">
      <div className="Events__wrapper Wrap">
        {!events.length && <span className="Events__placeholder">Здесь будет история работы над проектом</span>}
        {events.map(event => (
          <Event key={event.id} eventId={event.id}/>
        ))}
      </div>
    </div>
  );
}