import { TokensManager } from "../../../app/model/TokensManager";

import './LogoutButton.scss';

export const LogoutButton = () => {
  const handleClick = () => {
    TokensManager.dropTokens();
    window.location.pathname = '/login';
  };

  return (
    <button className="LogoutButton" onClick={handleClick}>
      Выход из личного кабинета
      <svg><use xlinkHref="#icon-exit"/></svg>
    </button>
  );
}