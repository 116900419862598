import React, { useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { AuthModel } from './app/model/AuthModel';
import { TokensManager } from './app/model/TokensManager';

export const SocketContext = React.createContext<Socket | null>(null);

export const useSocketRoom = (roomName: string) => {
  const socket = useContext(SocketContext) as Socket;

  useEffect(() => {
    socket.emit('join', roomName);

    return () => {
      socket.emit('leave', roomName);
    }
  }, [ socket, roomName ]);

  return socket;
}

type Props = {
  children: any
};

export const SocketWrapper: React.FC<Props> = ({ children }) => {
  const [ socket, setSocket ] = useState<Socket | null>(null);
  
  useEffect(() => {
    const createSocket = () => {
      const socket = io({
        path: '/api/ws/socket.io',
        extraHeaders: {
          Authorization: `Bearer ${TokensManager.getAccessToken()}`
        }
      });

      socket.on('connect_error', () => {
        socket.off();
        socket.disconnect();
        AuthModel.refreshToken().then(() => {
          setSocket(createSocket());
        });
      });

      return socket;
    }

    setSocket(createSocket);
  }, [ ]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
}