import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "../common/button/Button";
import { PageLayout } from "../common/pageLayout/PageLayout";
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { selectProfile, updateProfile } from "../auth/authSlice";
import { Profile } from "../../app/model/ProfileModel";
import { unwrapResult } from "@reduxjs/toolkit";
import { Helmet } from "react-helmet";

const validationSchema = yup.object().shape({
  firstName: yup.string().required('Обязательное поле'),
  middleName: yup.string().required('Обязательное поле'),
  lastName: yup.string().required('Обязательное поле'),
  email: yup.string().email('Не валидный E-Mail').required('Обязательное поле')
});

export const ProfilePage = () => {
  const dispatch: AppDispatch = useDispatch();
  const profile = useSelector((state: RootState) => selectProfile(state)) as Profile;

  return (
    <PageLayout>
      <Helmet>
        <title>Личные данные</title>
      </Helmet>
      <main className="Wrap">
        <h1 className="Heading Heading_level_1 mb-12">Личные данные</h1>
        <Formik
          validateOnBlur={false}
          initialValues={profile}
          validationSchema={validationSchema}
          onSubmit={async (values, formikContext) => {
            try {
              await dispatch(updateProfile(values)).then(unwrapResult);
            } catch (err) {
              if (err.error === 'duplicate_email') {
                return formikContext.setErrors({ email: 'Этот адрес уже есть в системе' });
              }
            }
            formikContext.resetForm({ values: values });
          }}
        >
          {({ isSubmitting, dirty }) => (
            <Form>
              <div className="mb-6">
                <label className="Label mb-2">Фамилия</label>
                <Field type="text" name="lastName" className="Input"/>
                <ErrorMessage name="lastName">
                  {msg => <span className="ErrorMessage mt-1">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="mb-6">
                <label className="Label mb-2">Имя</label>
                <Field type="text" name="firstName" className="Input"/>
                <ErrorMessage name="firstName">
                  {msg => <span className="ErrorMessage mt-1">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="mb-6">
                <label className="Label mb-2">Отчество</label>
                <Field type="text" name="middleName" className="Input"/>
                <ErrorMessage name="middleName">
                  {msg => <span className="ErrorMessage mt-1">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="mb-6">
                <label className="Label mb-2">Почта</label>
                <Field type="text" name="email" className="Input"/>
                <ErrorMessage name="email">
                  {msg => <span className="ErrorMessage mt-1">{msg}</span>}
                </ErrorMessage>
              </div>
              <Button type="submit" disabled={isSubmitting || !dirty} filled>Сохранить</Button>
            </Form>
          )}
        </Formik>
      </main>
    </PageLayout>
  );
}