import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Preloader } from '../common/preloader/Preloader';
import { fetchProfile, selectProfile } from './authSlice';

type Props = {
  children: any
};

export const ProfileWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => selectProfile(state));

  useEffect(() => {
    if (!profile) {
      dispatch(fetchProfile());
    }
  }, [ profile, dispatch ]);

  return profile
    ? children
    : <Preloader primary centering/>;
}