import { Button } from "../../../../../../../common/button/Button";

type Props = {
  projectUuid: string
  description: string
};

export const ProgressContent: React.FC<Props> = ({ projectUuid, description }) => (
  <>
    <p className="Paragraph mb-6" dangerouslySetInnerHTML={{ __html: description }}/>
    <Button filled to={`/briefings/${projectUuid}`}>Перейти к брифингу</Button>
  </>
);