import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Briefing, BriefingModel, BriefingStatuses } from "../../../app/model/BriefingModel";
import { RootState } from "../../../app/store";

export const fetchBriefing = createAsyncThunk('briefing/briefing/fetch',
  async (projectUuid: string) => await BriefingModel.fetchBriefing(projectUuid)
);

export const setStatusCheckBriefing = createAsyncThunk('briefing/briefing/setStatusCheck',
  async (projectUuid: string) => await BriefingModel.setStatusCheckBriefing(projectUuid)
);

const briefingEntityAdapter = createEntityAdapter<Briefing>({
  selectId: entity => entity.projectUuid
});

const briefingSlice = createSlice({
  name: 'briefing',
  initialState: briefingEntityAdapter.getInitialState(),
  reducers: {
    upsertOneBriefing: (state, action) => {
      briefingEntityAdapter.upsertOne(state, action);
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchBriefing.fulfilled, (state, action) => {
      briefingEntityAdapter.upsertOne(state, action.payload);
    });

    builder.addCase(setStatusCheckBriefing.fulfilled, (state, action) => {
      (state.entities[action.meta.arg] as Briefing).status = BriefingStatuses.CHECK;
    });
  }
});

export const {
  upsertOneBriefing
} = briefingSlice.actions;

export const {
  selectById: selectBriefingById
} = briefingEntityAdapter.getSelectors((state: RootState) => state.briefing.briefing);


export const briefingReducer = briefingSlice.reducer;