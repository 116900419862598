import { useState, useRef, useEffect } from "react";
import { AuthModel } from "../../app/model/AuthModel";

type Props = {
  phone: string
};

export const ReplySend: React.FC<Props> = ({ phone }) => {
  const DELAY = 60;
  const [ isDelay, setIsDelay ] = useState(true);
  const [ seconds, setSeconds ] = useState(DELAY);
  const intervalRef = useRef<any>(null);
  const handleRetry = () => {
    AuthModel.loginPhone({ phone });
    setIsDelay(true);
  };

  useEffect(() => {
    if (isDelay) {
      intervalRef.current = setInterval(() => {
        setSeconds(s => s - 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [ isDelay ]);

  useEffect(() => {
    if (seconds <= 0) {
      setIsDelay(false);
      setSeconds(DELAY);
    }
  }, [ seconds ]);

  return isDelay
    ? <span>Прислать код через {seconds} секунд</span>
    : <button className="Link" onClick={handleRetry}>Прислать код ещё раз</button>;
}

