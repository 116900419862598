import axios from 'axios';
import { Tokens, TokensManager } from './TokensManager';

export const axiosInstance = axios.create({
  baseURL: '/api'
});

axiosInstance.interceptors.request.use(config => {
  config.headers['Authorization'] = `Bearer ${TokensManager.getAccessToken()}`;
  return config;
});

let isRefreshing = false;
let failedQueue: any[] = [];

function resolveQueue(error: any, accessToken?: string): void {
  failedQueue.forEach(promise => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(accessToken);
    }
  });

  failedQueue = [];
}

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const originaConfig = error.config;

    if (originaConfig.url.indexOf('passport/auth/refresh-token') >= 0) {
      TokensManager.dropTokens();
      window.location.pathname = '/login';
    }

    if (error.response.status === 401 && !originaConfig._retry) {
      if (isRefreshing) {
        return new Promise<string>((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then((token: string) => {
          originaConfig.headers['Authorization'] = `Bearer ${token}`;
          return axiosInstance(originaConfig);
        }).catch(error => {
          return Promise.reject(error);
        });
      } else {
        isRefreshing = true;
        originaConfig._retry = true;

        return new Promise(async (resolve, reject) => {
          axiosInstance.post('passport/auth/refresh-token', {
            refreshToken: TokensManager.getRefreshToken()
          }).then(res => {
            const tokens = res.data as Tokens;
            TokensManager.setTokens(tokens);
            isRefreshing = false;
            resolveQueue(null, tokens.accessToken);
            originaConfig.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
            resolve(axiosInstance(originaConfig));
          });
        });
      }
    }

    return Promise.reject(error);
  }
);