import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { briefingNavigationPrapare } from "./briefingNavigationPrapare";

export type BriefingNav = {
  prevPath: string | null
  nextPath: string | null
  loaded?: boolean
};
export type ActiveEntity = {
  partId?: string
  stepId?: string
};

const navigationSlice = createSlice({
  name: 'brieifng/navigation',
  initialState: {
    loaded: false
  } as BriefingNav,
  reducers: {
    setNavigation: {
      reducer: (state, action: PayloadAction<BriefingNav>) => {
        return {
          ...action.payload,
          loaded: true
        };
      },
      prepare: briefingNavigationPrapare
    },
  }
});

export const selectBriefingNavigation = (state: RootState) => state.briefing.navigation;

export const { setNavigation: setBriefingNavigation } = navigationSlice.actions;

export const navigationReducer = navigationSlice.reducer;