import React, { useState, useRef } from "react";
import { AuthModel } from "../../app/model/AuthModel";
import InputMask from 'react-input-mask';
import { ReplySend } from "./ReplySend";

type Props = {
  phone: string
  onBack: any
  onSuccess: any
};

export const EnterCode: React.FC<Props> = ({ phone, onBack, onSuccess }) => {
  const [ code, setCode ] = useState('');
  const [ isSubmit, setIsSubmit ] = useState(false);
  const [ isCodeWrong, setIsCodeWrong ] = useState(false);
  const inputRef = useRef<any>(null);
  const handleChange = async (e: any) => {
    const { value } = e.target;

    setCode(value);

    if ((value.match(/\d/g) || []).length === 4) {
      submit(phone, value);
    }
  };
  const submit = async (phone: string, code: string) => {
    setIsSubmit(true);

    AuthModel.confirmEntry({ phone, code })
      .then(onSuccess)
      .catch(() => {
        setIsCodeWrong(true);
        setCode('');
        setIsSubmit(false);
        inputRef.current?.blur();
      });
  };

  return (
    <>
      <div className="mb-6">
        <label className="Label mb-2">Введите код</label>
        <InputMask
          ref={inputRef}
          className="Input"
          type="tel"
          placeholder="●●●●"
          maskPlaceholder=""
          aria-label="Код"
          mask="9999"
          value={code}
          onChange={handleChange}
          onInput={() => setIsCodeWrong(false)}
          readOnly={isSubmit}
        />
        {isCodeWrong && <span className="ErrorMessage mt-1">Неверный код</span>}
      </div>
      <div className="mb-2">
        <ReplySend phone={phone}/>
      </div>
      <div>
        <button className="Link" onClick={onBack}>Вернуться</button>
      </div>
    </>
  );
};