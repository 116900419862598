import { format } from 'date-fns';
import cn from 'classnames';
import { ru } from 'date-fns/esm/locale';

import './EventHeadline.scss';

type Props = {
  deadline: string
  children?: any
  className?: any
  done?: boolean
};

export const EventHeadline: React.FC<Props> = ({ deadline, children, className, done }) => {
  const formatedDeadline = format(new Date(deadline), 'dd MMM', { locale: ru });

  return (
    <div className={cn('EventHeadline', className)}>
      {children && (
        <div className="EventHeadline__item">
          {children}
        </div>
      )}
      <div className="EventHeadline__item">
        <svg><use xlinkHref={done ? '#icon-checkbox' : '#icon-clock'}/></svg>
        {done ? 'Завершен' : `до ${formatedDeadline}`}
      </div>
    </div>
  );
};