import { useState } from 'react';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Button } from '../common/button/Button';
import { AuthModel } from '../../app/model/AuthModel';
import { PageLayout } from '../common/pageLayout/PageLayout';
import * as yup from 'yup';

import './LoginPage.scss';

const validationSchema = yup.object().shape({
  phone: yup.string().required('Обязательное поле'),
  password: yup.string().required('Обязательное поле')
});

export const PasswordLoginPage = () => {
  const location = useLocation();
  const history = useHistory();
  const state = location.state as any;
  const [ isAuthErr, setIsAuthErr ] = useState(false);
  const handleFormChange = () => {
    if (isAuthErr) setIsAuthErr(false);
  };

  if (AuthModel.isAuthenticated()) {
    return <Redirect to="/"/>;
  }

  return (
    <PageLayout>
      <div className="LoginPage Wrap">
        <h1 className="Heading Heading_level_1 mb-4">Личный кабинет</h1>
        <p className="Paragraph mb-8">Инструмент для работы с Art Studio HOUSE</p>
        <Formik
          initialValues={{
            phone: '',
            password: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async values => {
            try {
              await AuthModel.loginPassword(values);
            } catch (err) {
              setIsAuthErr(true);
              return;
            }

            history.push(state && state.referrer ? state.referrer : '/');
          }}
        >
          {({ isSubmitting }) => (
            <Form className="LoginPage__form mb-12" onChange={handleFormChange}>
              <div className="mb-6">
                <Field name="phone" className="Input" placeholder="Телефон">
                </Field>
                <ErrorMessage name="phone">
                  {msg => <span className="ErrorMessage mt-1">{msg}</span>}
                </ErrorMessage>
              </div>
              <div className="mb-6">
                <Field className="Input" type="password" name="password" placeholder="Пароль" aria-label="Пароль"/>
                <ErrorMessage name="password">
                  {msg => <span className="ErrorMessage mt-1">{msg}</span>}
                </ErrorMessage>
                {isAuthErr && <div className="ErrorMessage mt-2">Неправильный телефон или пароль</div>}
              </div>
              <Button type="submit" border disabled={isSubmitting}>Войти</Button>
            </Form>
          )}
        </Formik>
      </div>
    </PageLayout>
  );
};
