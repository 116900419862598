import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { BriefingContent, BriefingModel } from "../../../app/model/BriefingModel";
import { RootState } from "../../../app/store";

export const fetchContent = createAsyncThunk('briefing/content',
  async (projectUuid: string) => await BriefingModel.fetchContent(projectUuid)
);

const contentEntityAdapter = createEntityAdapter<BriefingContent>({
  selectId: entity => entity.projectUuid
});

const contentSlice = createSlice({
  name: 'content',
  initialState: contentEntityAdapter.getInitialState({
    isOpen: false
  }),
  reducers: {
    toggleContent(state) {
      state.isOpen = !state.isOpen
    },
    closeContent(state) {
      state.isOpen = false;
    },
    updateContentProgress(state, action) {
      state.ids.forEach(id => {
        const { parts } = state.entities[id] as BriefingContent;
        parts.forEach(part => {
          part.steps.forEach(s => {
            s.progress = action.payload[s.id];
          });
        });
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchContent.fulfilled, (state, action) => {
      contentEntityAdapter.upsertOne(state, action.payload);
    });
  }
});

export const { toggleContent, closeContent, updateContentProgress } = contentSlice.actions;

export const selectIsOpenContent = (state: RootState) => state.briefing.content.isOpen;

export const {
  selectById: selectContentById
} = contentEntityAdapter.getSelectors((state: RootState) => state.briefing.content);

export const contentReducer = contentSlice.reducer;