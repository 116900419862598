import { File } from "../../../../../../app/model/ObjectStorageModel";
import { Attachment } from "./Attachment/Attachment";
import cn from 'classnames';

import './AttachmentList.scss';

type Props = {
  files: File[],
  className?: any,
  deleted?: (uuid: string) => void
};

export const AttachmentList: React.FC<Props> = ({ files, deleted, className }) => {
  if (!files.length) {
    return null;
  }

  return (
    <ul className={cn('AttachmentList', className)}>
      {files.map(f => (
        <li key={f.name}>
          <Attachment file={f} deleted={deleted}/>
        </li>
      ))}
    </ul>
  );
}