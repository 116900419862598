import { useDispatch, useSelector } from 'react-redux';
import { selectMenuOpen, toggleMenu } from '../menuSlice';
import cn from 'classnames';

import './Hamburger.scss';

export const Hamburger = () => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(selectMenuOpen);
  const handleClick = () => dispatch(toggleMenu());

  return (
    <button
      type="button"
      aria-label="Кнопка открытия меню"
      className={cn('Hamburger', { 'Hamburger_active': isMenuOpen })}
      onClick={handleClick}
    >
      <span className="Hamburger__middle-line"></span>
      <span className="Hamburger__click-area"></span>
    </button>
  );
}