import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { selectProgress, fetchProgress, setProgress } from "../../features/briefing/store/progressSlice";
import { RootState } from "../store";
import { useSocketRoom } from '../../SocketWrapper';

export const useProjectProgress = (projectUuid: string) => {
  const dispatch = useDispatch();
  const progress = useSelector((state: RootState) => selectProgress(state));
  const socketRoom = useSocketRoom(`briefings/${projectUuid}/progressUpdated`);

  useEffect(() => {
    dispatch(fetchProgress(projectUuid));

    socketRoom.on('progressUpdated', (progress: string) => {
      dispatch(setProgress(progress));
    });
  }, [ projectUuid, socketRoom, dispatch ]);

  return progress;
}