import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "../../app/hooks/useQuery";
import { AuthModel } from "../../app/model/AuthModel";
import { TokensManager } from "../../app/model/TokensManager";
import { Preloader } from "../common/preloader/Preloader";

export const AuthenticationKey = () => {
  const history = useHistory();
  const query = useQuery();
  const { key } = useParams() as {key: string};
  const clientUuid = query.get('clientUuid') as string;
  const redirect = query.get('redirect') || '/';

  useEffect(() => {
    TokensManager.dropTokens();
  }, []);

  useEffect(() => {
    AuthModel.loginAuthenticationKey({ clientUuid, key }).then(() => {
      history.push(redirect);
    }).catch(() => {
      history.push('/');
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Preloader primary centering/>;
};