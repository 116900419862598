import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { BriefingModel, Step } from "../../../app/model/BriefingModel";
import { RootState } from "../../../app/store";

export const fetchStep = createAsyncThunk('briefing/step',
  async (stepId: string) => await BriefingModel.fetchStep(stepId)
);

const stepEntityAdapter = createEntityAdapter<Step>();

const stepSlice = createSlice({
  name: 'step',
  initialState: stepEntityAdapter.getInitialState(),
  reducers: { },
  extraReducers: builder => {
    builder.addCase(fetchStep.fulfilled, (state, action) => {
      stepEntityAdapter.upsertOne(state, action.payload);
    });
  }
});

export const {
  selectById: selectStepById
} = stepEntityAdapter.getSelectors((state: RootState) => state.briefing.step);


export const stepReducer = stepSlice.reducer;