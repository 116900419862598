import { TimelineEvent, TimelineContractAct, TimelineStage } from "../../../../../../app/model/ProjectTimelineModel";
import { UserEventLayout } from "./userEventLayout/UserEventLayout";
import { EventInfo } from "./eventInfo/EventInfo";
import { PrivateFile } from "../../../../../common/privateFile/PrivateFile";
import { Preloader } from "../../../../../common/preloader/Preloader";
import { useSelector } from "react-redux";
import { selectLastStageEvent } from "../../../../store/timelineEventSlice";
import { RootState } from "../../../../../../app/store";

type Props = {
  event: TimelineEvent
};

export const ContractActType: React.FC<Props> = ({ event }) => {
  const act = event.data as TimelineContractAct;
  const lastStageEvent = useSelector((state: RootState) => selectLastStageEvent(state));
  const stage: TimelineStage | null = lastStageEvent ? lastStageEvent.data : null;

  return (
    <UserEventLayout icon="sign">
      <EventInfo date={event.createdAt} className="mb-1"/>
      {stage && (
        <div className="mb-2">Завершён Этап №{stage.number}. «{stage.name}».</div>
      )}
      <span className="Event__title mb-1">Добавлен акт на подпись</span>
      <PrivateFile name={act.file[0].name} render={({ handleDownload, file }) => (
        <div className="Paragraph">
          Пожалуйста, распечатайте файл&nbsp;
          {file
              ? <button className="Link" onClick={handleDownload}>{file.originalName}</button>
              : <Preloader inline/>
          }
          , подпишите его, а затем загрузите скан-копию в систему.
        </div>
      )}/>
    </UserEventLayout>
  );
};